import React, { useState } from "react";
import '../OperatingSystem/css/operatingsysyemCat.css';
import { useNavigate } from "react-router-dom";



// Sample data (You can replace this with dynamic data later)
const categories = [


  {
    id:1,
    image: 'images/operatingsystem/How-to-Transfer-Photos-From-iPhone-to-Mac-144x144.jpg',
    title: 'How to Transfer Photos From iPhone to Mac',
    time: '2024-09-12',
    details: 'Are you running out of space on your iPhone and need to transfer photos to your Mac? Or maybe you want to back up your precious memories to avoid losing them? Whatever your reason, transferring photos from an iPhone to a Mac is a simple process that can be done in various ways. Here’s how you can do it.'
  },
  {
    id: 2,
    image: 'images/operatingsystem/Windows10.jpg',
    title: 'Windows 10 SP1 Augest 2024 ',
    time: '2024-09-10',
    details: 'Windows 10 April 2023 Free Download Latest OEM RTM version. It is Full Bootable ISO Image of Windows 10 April 2023 Free Download.'
  }
  

 
];





// Component to display individual category information
const CategoryCard = ({ category, onClick }) => {


  return (
    <div className="category-card" onClick={onClick}>
      <img src={category.image} alt={category.title} className="category-image" />
      <div className="category-info">
        <h3>{category.title}</h3>
        <p className="category-time">Added on: {category.time}</p>
        <p>{category.details}</p>
      </div>
    </div>
  );
};





// Main Component to display the list of categories
const EducationCategoryList = () => {
  const navigate = useNavigate();  // React Router's navigation hook

  const handleCardClick = (categoryId) => {
    if (categoryId === 1) {
      navigate("/PhotoTransferGuide"); // Navigate to the Windows 7 page
    } else if (categoryId === 2) {
      navigate(""); // Navigate to the Windows 10 page
    }
    // Add more conditions for other categories if needed
  };

  return (
    <div className="Main-category">
    <div className="category-list">
      {categories.map((category) => (
        <CategoryCard key={category.id} category={category} onClick={() => handleCardClick(category.id)} />
      ))}


    </div>
   
    </div>
  );
};

export default EducationCategoryList;




