import React from 'react';
import '../css/page2Categories.css';
import './NextButton.css';
import { useNavigate } from "react-router-dom";
// Sample data (You can replace this with dynamic data later)
const Page2Categories = [
  {
    id:1,
    image: 'images/MostP/Maxon Cinema 4D.jpg',
    title: 'Free Download Maxon Cinema 4D  ',
    time: '2025-02-08',
    details: 'Maxon Cinema 4D is a professional 3D modeling, animation, and rendering software that is widely used for motion graphics, visual effects and design. Known for its user -friendly interfaces and powerful devices, it basically integrates with other creative applications such as Adobe after effects.'
  },
 

  {
    id:2,
    image: 'images/MostP/Adguard Ad Blocker.jpg',
    title: 'Free Download Adguard Ad Blocker 2025 ',
    time: '2025-01-08',
    details: 'Adguard Ad Blober 2025 is a state -of -the -art device designed to block advertisements, trackers and malware in websites and apps, which ensures a fast and safe browsing experience. With advanced facilities such as advanced privacy protection and adaptable filters, it remains a top option for users who are looking for spontaneous advertising internet access.'
  },
  {
    id: 3,
    image: 'images/MostP/Applied Imagery Quick Terrain Modeler.jpg',
    title: ' Free Download Applied Imagery Quick Terrain Modeler  ',
    time: '2025-01-08',
    details: 'Applied imagery Quick Terren Modelor (QTM) is a powerful software tool, designed to process, analyze and visualize high-resolution data including lidar and elevation dataset. It is widely used in areas such as defense, environmental studies and urban planning for its advanced 3D modeling, mapping and analysis capabilities.'
  },
  {
    id: 4,
    image: 'images/MostP/GPS Compass.jpg',
    title: 'Free Download GPS Compass',
    time: '2025-01-08',
    details: 'GPS Compass is a Windows application that combines GPS navigation with a digital compass, providing accurate location trekking and directional guidance. It is widely used for external activities, hiking and travel, providing real -time coordinates, height and speed information for increased navigation and orientation.'
  }
  ,
  {
    id: 5,
    image: 'images/MostP/IObit Malware Fighter.jpg',
    title: 'Free Download IObit Malware Fighter',
    time: '2025-01-08',
    details: 'The IOBIT Malware Fighter is a strong safety software designed to detect and remove malware, spyware and other online hazards in real time. It has advanced anti-ranmware security, browser tracking prevention and a safe browsing environment to ensure advanced system safety and privacy.'
  }
  

  ,
  {
    id: 6,
    image: 'images/MostP/McAfee Endpoint Security.jpg',
    title: 'Free Download McAfee Endpoint Security ',
    time: '2025-01-08',
    details: 'Mcafee and option security is a comprehensive cyber security solution designed to protect the enterprise closing points from advanced hazards, malware and unauthorized access. It adds anti-malware, firewall, web control and threath prevention technologies to the same platform, which ensures strong security and centralized management for businesses.'
  },



  {
    id: 7,
    image: 'images/MostP/Nsauditor Network Security Auditor.jpg',
    title: 'Free Download Nsauditor Network Security Auditor',
    time: '2025-01-08',
    details: 'NSAUDITOR Network Safety Auditor is a powerful network management and safety tool designed for monitoring, analysis and audit network systems for weaknesses and performance issues. This network provides a variety of features including scanning, real-time traffic monitoring, and safety assessment, which helps administrators to maintain a safe and efficient network infrastructure.'
  },
  {
    id: 8,
    image: 'images/MostP/Perfectly Clear WorkBench 2025.jpg',
    title: 'Free Download Perfectly Clear WorkBench 2025',
    time: '2025-01-08',
    details: 'Completely clear workspace 2025 is an advanced photo improvement and growth software designed to automate and effective the editing process for professional-quality results. Taking advantage of AI-mangoing technology, it provides a colored solution for color correction, skin retaching and image clarity, making it a tool for photographers and designers who are looking for efficiency and accuracy.'
  },
  {
    id: 9,
    image: 'images/MostP/Parted Magic 2025.jpg',
    title: 'Free Download Parted Magic 2025',
    time: '2025-01-08',
    details: 'Parted Magic 2025 is a versatile and powerful disc management and data recovery tool designed to eradicate partitions, cloning and safely. Known for its user -friendly interfaces and strong features, it is widely used by enthusiastic people for IT professionals and system maintenance, data rescue and safe disk wipe tasks.'
  }
  ,
  {
    id: 10,
    image: 'images/MostP/MS Office 2010 SP2 Pro Plus.jpg',
    title: 'Free Download MS Office 2010 SP2 Pro Plus ',
    time: '2025-01-08',
    details: 'Microsoft Office 2010 Sp2 Professional Plus is a comprehensive productivity suit that includes essential applications such as Word, Excel, PowerPoint, Outlook and Access, designed for professional and commercial use. SP2 (Service Pack 2) Update increases stability, safety and performance improvement to ensure a more reliable and efficient user experience.'
  }
  

  ,
  {
    id: 11,
    image: 'images/MostP/Microsoft Office Professional Plus.jpg',
    title: 'Free Download Microsoft Office Professional Plus ',
    time: '2025-01-08',
    details: 'Microsoft Office Professional Plus is a premium version of Microsoft Office Suite, which is sewn for business and professional use. This includes advanced apps such as Word, Excel, PowerPoint, Outlook, Access, Publisher and Skype, providing strong equipment for document construction, data analysis, communication and cooperation.'
  }
];








// Component to display individual category information
const CategoryCard = ({ category, onClick }) => {
  return (
    <div className="category-card"  onClick={onClick}>
      <img src={category.image} alt={category.title} className="category-image" />
      <div className="category-info">
        <h3>{category.title}</h3>
        <p className="category-time">Added on: {category.time}</p>
        <p>{category.details}</p>
      </div>
    </div>
  );
};









// Main Component to display the list of categories
const Page2CategoryList = () => {

  const navigate = useNavigate();  // React Router's navigation hook

  const handleCardClick = (categoryId) => {
    if (categoryId === 1) {
      // navigate("/IDM");

      window.location.href = "https://getintopc.com/softwares/3d-animation/maxon-cinema-4d-2025-free-download/";
    } else if (categoryId === 2) {
        // navigate("/AvastAntivirusoft"); 
        window.location.href = "https://getintopc.com/softwares/adblocker/adguard-ad-blocker-2024-free-download/";
    } else if (categoryId === 3) {
      window.location.href = "https://getintopc.com/softwares/3d-cad/applied-imagery-quick-terrain-modeler-2025-free-download/";

    }else if (categoryId === 4) {
    window.location.href = "https://getintopc.com/mobile/android/gps-compass/";
    }else if (categoryId === 5) {
      window.location.href = "https://getintopc.com/softwares/antivirus/iobit-malware-fighter-2024-free-download/";
    }
    else if (categoryId === 6) {
      window.location.href = "https://getintopc.com/softwares/mcafee-endpoint-security-2021-free-download-1541808/";
    }
    else if (categoryId === 7) {
      window.location.href = "https://getintopc.com/softwares/network/nsauditor-network-security-auditor-2022-free-download/";
    }
    else if (categoryId === 8) {
      window.location.href = "https://getintopc.com/softwares/photo-retouching/perfectly-clear-workbench-2025-free-download/";
    }
    else if (categoryId === 9) {
      window.location.href = "https://getintopc.com/softwares/disk-management/parted-magic-2025-free-download/";
    }
    else if (categoryId === 10) {
      window.location.href = "https://getintopc.com/softwares/ms-office-2010-sp2-pro-plus-vl-x64-june-2020-free-download-1151297/";
    }
    else if (categoryId === 11) {
      window.location.href = "https://getintopc.com/softwares/office-tools/microsoft-office-professional-plus-2024-free-download/";
    }

  };



  const Loadpage1 = () => {

    navigate("/"); 

  }

  return (
    <div className="Main-category">
    <div className="category-list">
      {Page2Categories.map((category) => (
        <CategoryCard  key={category.id} category={category} onClick={() => handleCardClick(category.id)} />
      ))}
    </div>
  

    <button className="next-button" onClick={Loadpage1} >
      1
      
    </button>
    <button className="next-button2">
      3
      {/* <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 5L8 5" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5 2L5 8" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg> */}
    </button>
  
    </div>
  );
};

export default Page2CategoryList;
