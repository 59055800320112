import React from 'react';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';
import './css/windows10sp1.css';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import ReactPlayer from "react-player";


const Windows11New = () => {



    const handleDownload = () => {
      // Your download logic here
      // window.location.href = 'SoftwareHere/OperatingSystems/EasyPcSoft.com_Windows_11.iso'; // Example link
      window.location.href = 'https://getintopc.com/softwares/operating-systems/windows-11-april-2023-free-download-2971397/';
    };

  


  return (
    <div class="background-image">
   <TopNavbar />
   <LogoComponent />
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 
    
     <h2 style={{ textAlign: 'center', color: '#00c9ff', marginTop: '10px' }}>Detailed Overview of Windows 11</h2>
      <p style={{ paddingLeft: '5px' }}>
      Windows 11 is Microsoft's latest operating system, designed to be a major upgrade from Windows 10...
        About modern design Increased efficiency and new features New look Improved productivity tools.
        and additional integrations with Microsoft Teams, Xbox, and other services. Here's a detailed overview of the key points.
      </p>

      <h2 style={{ paddingLeft: '20px' , color: '#00c9ff' }}>1. Modern User Interface</h2>
      <p style={{ paddingLeft: '5px' }}>
        Windows 11 introduces a visually striking user interface with rounded corners, a centered taskbar, and a
        simplified Start menu. The design is minimalistic yet elegant, aimed at providing a more user-friendly and
        cleaner experience:
      </p>
      <ul>
        <li>
          <strong>Start Menu:</strong> The Start menu has been simplified and moved to the center of the taskbar. It
          showcases pinned apps, recent files, and recommended apps with a cloud-powered dynamic feed.
        </li>
        <li>
          <strong>Taskbar:</strong> The taskbar is also centered by default, providing a more balanced and intuitive
          layout. It has been designed to accommodate touch and pen inputs, making it more accessible across devices.
        </li>
        <li>
          <strong>Themes and Personalization:</strong> Windows 11 allows for extensive customization, with dark and
          light themes and additional color and wallpaper settings to personalize the desktop environment.
        </li>
      </ul>

      <h2>2. Productivity Features</h2>
      <p style={{ paddingLeft: '5px' }}> Windows 11 focuses heavily on boosting productivity and ease of use, offering several new tools to help manage workflows:</p>
      <ul>
        <li>
          <strong>Snap Layouts and Snap Groups:</strong> These features allow users to organize open windows into different layouts
          easily. Snap Layouts enable precise window management, while Snap Groups save and restore groupings of windows,
          making it easier to return to a specific arrangement.
        </li>
        <li>
          <strong>Virtual Desktops:</strong> Improved virtual desktop functionality allows users to create multiple desktops for
          different tasks, like work, gaming, or personal use. Each virtual desktop can have a unique layout, theme, and
          application set.
        </li>
        <li>
          <strong>Widgets:</strong> A new Widgets feature provides quick access to personalized content, such as news, weather,
          calendar events, and to-do lists. Widgets are customizable and can be accessed by swiping from the left side of
          the screen.
        </li>
        <li>
          <strong>Microsoft Teams Integration:</strong> Microsoft Teams is built directly into the taskbar, allowing users to
          quickly start chats or video calls. This feature is designed to facilitate better communication, whether for work
          or personal use.
        </li>
      </ul>
      <img 
        src='/images/operatingsystem/free download 11.jpeg' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />
      <h2 style={{ paddingLeft: '20px' , color: '#00c9ff' }}>3. Gaming Enhancements</h2>
      <p style={{ paddingLeft: '5px' }}>Windows 11 is built with gamers in mind, offering several new features and optimizations to improve gaming performance:</p>
      <ul>
        <li>
          <strong>DirectStorage:</strong> This feature significantly reduces load times and enhances the performance of games by
          allowing the GPU to load assets directly from the SSD.
        </li>
        <li>
          <strong>Auto HDR:</strong> Auto HDR automatically enhances lighting and contrast in games, providing a more vibrant and
          immersive experience, even for titles that were not originally designed with HDR in mind.
        </li>
        <li>
          <strong>Xbox Game Pass:</strong> Windows 11 integrates with the Xbox app, providing access to Xbox Game Pass, a
          subscription service that offers a large library of games for PC gamers.
        </li>
        <li>
          <strong>Improved Performance:</strong> Windows 11’s optimizations provide smoother gaming performance with better frame
          rates, reducing latency and enhancing the overall gaming experience.
        </li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#00c9ff' }}>4. Security and Privacy</h2>
      <p>Security is a major focus in Windows 11, with Microsoft aiming to provide users with greater protection against malware, ransomware, and other threats:</p>
      <ul>
        <li><strong>Trusted Platform Module (TPM) 2.0:</strong> TPM 2.0 is required for Windows 11, offering hardware-based security. This module provides encryption, secure boot, and other protections against unauthorized access to your system.</li>
        <li><strong>Windows Hello:</strong> Biometric authentication via facial recognition, fingerprints, or PIN is improved for faster and more secure logins.</li>
        <li><strong>Microsoft Defender:</strong> Built-in malware protection and advanced threat detection ensure that users are protected against the latest cyber threats.</li>
        <li><strong>Secure Boot:</strong> Prevents unauthorized software from loading during startup, ensuring that your system is secure from the moment it powers on.</li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#00c9ff' }}>5. Touch, Pen, and Voice Input</h2>
      <p style={{ paddingLeft: '5px' }}>Windows 11 offers enhanced support for different types of input, making it versatile across touchscreens, tablets, and hybrid devices:</p>
      <ul>
        <li><strong>Touch Optimizations:</strong> The UI has been redesigned for easier touch navigation, with larger touch targets and improved gesture controls.</li>
        <li><strong>Pen Support:</strong> Windows 11 enhances the stylus experience, providing better inking features, including haptic feedback when drawing or writing.</li>
        <li><strong>Voice Typing:</strong> Voice recognition has been improved, allowing for more accurate and faster dictation across the OS.</li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#00c9ff' }}>6. System Requirements</h2>
      <p style={{ paddingLeft: '5px' }}>Windows 11 comes with stricter hardware requirements compared to Windows 10, ensuring smooth operation on modern devices. Minimum requirements include:</p>
      <ul>
        <li><strong>Processor:</strong> 1 GHz or faster with at least 2 cores on a compatible 64-bit processor or SoC.</li>
        <li><strong>RAM:</strong> 4 GB or higher.</li>
        <li><strong>Storage:</strong> 64 GB or more.</li>
        <li><strong>Firmware:</strong> UEFI, Secure Boot capable.</li>
        <li><strong>TPM:</strong> Trusted Platform Module (TPM) version 2.0.</li>
        <li><strong>Graphics Card:</strong> DirectX 12 or later compatible with WDDM 2.0 driver.</li>
        <li><strong>Display:</strong> High-definition (720p) display that is greater than 9” diagonally.</li>
        <li><strong>Internet Connection:</strong> Required for setting up the system, downloading updates, and using certain features.</li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#00c9ff' }}>7. Software and App Compatibility</h2>
      <p style={{ paddingLeft: '5px' }}>
      Windows 11 maintains strong backward compatibility with apps running on Windows 10, ensuring users can continue…
        using your favorite software But the operating system also has a Windows subsystem for Android, which allows users to
        To run Android apps within Windows 11 via the Microsoft Store.
      </p>

      <h2 style={{ paddingLeft: '20px' , color: '#00c9ff' }}>8. Performance and Efficiency</h2>
      <p style={{ paddingLeft: '5px' }}>Windows 11 is designed to be faster and more efficient, with reduced resource consumption and improved power management. This results in longer battery life for laptops and faster wake times, enhancing the overall experience.</p>

      <h2 style={{ paddingLeft: '20px' , color: '#00c9ff' }}>Conclusion</h2>
      <p style={{ paddingLeft: '5px' }}>
      Windows 11 is a comprehensive upgrade to the Windows ecosystem. By presenting a modern design. Productivity tools
        Advanced gaming features and strong security enhancements Although the system requirements are more stringent. But the operating system is strict.
        Designed to take advantage of modern hardware Deliver a smoother and more responsive experience on the device.
        For work, gaming, or entertainment, Windows 11 provides users with a complex and powerful platform.
      </p>


      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
   
        marginBottom:'20px',
      }}
    >
      Free Download Windows 11
    </Button>
</div>

<p  style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
            {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<DownloadIcon />}
               
                style={{
                  backgroundColor: 'green',
                  color: '#fff',
                  padding: '10px 20px',
                  marginLeft: '10px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px',
                }}
              >
                How to download and install 
              </Button>

              </div> */}



 <div >
      <h2 style={{ paddingTop: '20px' , paddingLeft: '20px' ,  color: '#fa370c' }}>  How to download and install Check the below video </h2>
      <ReactPlayer
      style={{ paddingTop: '20px'  }}
        url="https://youtu.be/pywWR0zle10"
        controls={true} // Show controls for the player
        width="100%"    // Set the width of the player
        height="500px"  // Set the height of the player
      />
    </div>




      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default Windows11New ;
