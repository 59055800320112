import React from 'react';

import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TopNavbar from '../HeaderAndFooter/TopHeader';
import LogoComponent from '../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../MenuBar/SocialMediaSidebar';
import MenuBar from '../MenuBar/MenuBar';
import Footer from '../HeaderAndFooter/Footer';
import ReactPlayer from "react-player";


const WhatsAppMessenger1= () => {



    const handleDownload = () => {
      // Your download logic here
      window.location.href = 'SoftwareHere/homesoft/WhatsApp Installer.exe';

      // window.location.href = 'https://www.whatsapp.com/download';
    };

    
    // const handleclickvideos = () => {
    //   // Your download logic here
    //   // window.location.href = 'SoftwareHere/homesoft/';

    //   window.location.href = 'https://youtu.be/qOj3H4ab7tk';
    // };




  return (
    <div class="background-image">
   <TopNavbar/>
   <LogoComponent />
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h1 style={{ textAlign: 'center', color: '#08c916', marginTop: '10px' }}>Overview of WhatsApp Messenger 64-bit for PC Windows</h1>
      <p style={{ paddingLeft: '5px' }}>
      WhatsApp Messenger is a widely used messaging application that allows users to send messages, voice messages, and make audio and video calls. Share photos, documents, and other media across the internet. The 64-bit version for Windows provides a smooth desktop experience for your friends and users. Direct families from their PC Co want to connect.
      </p>

      <h2 style={{ paddingLeft: '20px' , color: '#08c916' }}>Key Features</h2>
      <ul>
        <li><strong>Messaging:</strong> Send text messages to individuals or groups with real-time notifications.</li>
        <li><strong>Voice and Video Calls:</strong> Make high-quality voice and video calls to other WhatsApp users, no matter where they are in the world.</li>
        <li><strong>Media Sharing:</strong> Easily share photos, videos, documents, and other files directly from your PC.</li>
        <li><strong>End-to-End Encryption:</strong> Ensures that your messages and calls are secure and private, accessible only by the intended recipients.</li>
        <li><strong>Desktop Notifications:</strong> Receive alerts for new messages and calls even when the app is not actively open.</li>
        <li><strong>Group Chats:</strong> Create and manage group chats with up to 256 participants, making it easy to stay in touch with larger circles.</li>
        <li><strong>WhatsApp Web Integration:</strong> Synchronizes with the mobile app, allowing users to access their messages on both platforms.</li>
        <li><strong>User-Friendly Interface:</strong> The intuitive design makes it easy for users to navigate the app and find features quickly.</li>
        <li><strong>Customizable Notifications:</strong> Set custom notification tones for different chats and groups.</li>
        <li><strong>Dark Mode:</strong> Offers a dark theme for comfortable viewing, especially in low-light environments.</li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#08c916' }}>System Requirements</h2>
      <ul>
        <li><strong>Operating System:</strong> Windows 10 (64-bit) or later</li>
        <li><strong>Processor:</strong> Intel Pentium 4 or later</li>
        <li><strong>RAM:</strong> Minimum of 2 GB (4 GB recommended)</li>
        <li><strong>Free Disk Space:</strong> At least 1 GB for installation</li>
        <li><strong>Display:</strong> 1280 x 800 resolution or higher</li>
        <li><strong>Internet Connection:</strong> Required for initial setup and ongoing use</li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#08c916' }}>Conclusion</h2>
      <p>
      WhatsApp Messenger for PC provides a convenient way to stay in touch with friends, family, and co-workers. With strong features and secure messaging capabilities It is an ideal choice for users who want to improve their communication experience on desktop platforms. Whether you're sending a quick message or making a video call, WhatsApp keeps you connected seamlessly.
      </p>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '20px',
      }}
    >
   WhatsApp Messenger 64-bit  for Windows
    </Button>

    </div>

    <p>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
            {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<DownloadIcon />}
                onClick={handleclickvideos}
                style={{
                  backgroundColor: 'green',
                  color: '#fff',
                  padding: '10px 20px',
                  marginLeft: '10px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px',
                }}
              >
                How to download and install 
              </Button>
              </div> */}


<div >
      <h2 style={{ paddingTop: '20px' , paddingLeft: '20px' ,  color: '#4f4bff' }}>  How to download and install Check the below video </h2>
      <ReactPlayer
      style={{ paddingTop: '20px'  }}
        url="https://youtu.be/qOj3H4ab7tk'"
        controls={true} // Show controls for the player
        width="100%"    // Set the width of the player
        height="500px"  // Set the height of the player
      />
    </div>





      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default WhatsAppMessenger1;
