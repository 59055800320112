import React from 'react';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TopNavbar from '../HeaderAndFooter/TopHeader';
import LogoComponent from '../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../MenuBar/SocialMediaSidebar';
import MenuBar from '../MenuBar/MenuBar';
import Footer from '../HeaderAndFooter/Footer';
import ReactPlayer from "react-player";


const AnyDesk= () => {



    const handleDownload = () => {
      // Your download logic here
      // window.location.href = 'https://anydesk.com/en/downloads/windows';

      window.location.href = 'SoftwareHere/homesoft/AnyDesk.exe';
    };


    // const handleclickvideos = () => {
    //   // Your download logic here
    //   window.location.href = 'https://youtu.be/yQKodC1zp8k';

    //   // Example link
    // };


    


  return (
    <div class="background-image">
   <TopNavbar/>
   <LogoComponent />
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2 style={{ textAlign: 'center', color: '#fa370c', marginTop: '10px' }}>Overview of AnyDesk for Windows</h2>
      <p style={{ paddingLeft: '5px' }}>
      AnyDesk is remote desktop software that allows users to connect to other computers remotely. Providing access to files and applications from anywhere, AnyDesk is known for its high performance and low latency. Suitable for both personal and professional use. It allows users to support, collaborate, or access remotely. their workstation.
      </p>

      <h2 style={{ paddingLeft: '20px' , color: '#fa370c' }}>Key Features</h2>
      <ul>
        <li><strong>High Performance:</strong> Offers low latency and fast data transmission, ensuring smooth remote connections even over limited bandwidth.</li>
        <li><strong>Cross-Platform Compatibility:</strong> Supports a variety of operating systems, including Windows, macOS, Linux, Android, and iOS, enabling connections across different devices.</li>
        <li><strong>File Transfer:</strong> Allows users to easily transfer files between local and remote devices with drag-and-drop functionality.</li>
        <li><strong>Remote Printing:</strong> Users can print documents from the remote desktop directly to their local printer.</li>
        <li><strong>Session Recording:</strong> Provides the ability to record remote sessions for documentation and training purposes.</li>
        <li><strong>Unattended Access:</strong> Allows users to set up unattended access to devices, making it easy to connect without user interaction.</li>
        <li><strong>Security Features:</strong> Utilizes TLS 1.2 encryption and other security protocols to protect user data during remote sessions.</li>
        <li><strong>Address Book:</strong> Keeps track of frequently accessed devices for quick and easy connections.</li>
        <li><strong>Multi-User Support:</strong> Supports multiple users connecting to a single device, facilitating collaborative work sessions.</li>
        <li><strong>Customizable Interface:</strong> Users can customize the AnyDesk interface to suit their preferences and workflow.</li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#fa370c' }}>System Requirements</h2>
      <ul>
        <li><strong>Operating System:</strong> Windows 10, Windows 8/8.1, Windows 7, or later</li>
        <li><strong>Processor:</strong> Intel Pentium 4 or later</li>
        <li><strong>RAM:</strong> Minimum of 2 GB (4 GB recommended)</li>
        <li><strong>Free Disk Space:</strong> At least 200 MB for installation</li>
        <li><strong>Display:</strong> 1024 x 768 resolution or higher</li>
        <li><strong>Internet Connection:</strong> Required for remote access and updates</li>
      </ul>


      <img 
      src='/images/MostP/anydeskeasypcsoft.png' 
        alt="window11 logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' 
        }}
        
      />




      <h2 style={{ paddingTop: '20px', paddingLeft: '20px' , color: '#fa370c' }}>Conclusion</h2>
      <p>
      AnyDesk is a powerful and versatile remote desktop application that supports a wide range of users. From people who need access to a computer from time to time. To businesses that need a robust remote support solution with an impressive feature set and an easy-to-use interface, AnyDesk is a great choice.
      </p>


      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>

      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '10px',
      }}
    >
   AnyDesk for Windows
    </Button>
</div>



    <p>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
            {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<DownloadIcon />}
                onClick={handleclickvideos}
                
                style={{
                  backgroundColor: 'green',
                  color: '#fff',
                  padding: '10px 20px',
                  marginLeft: '10px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px',
                }}
              >
                How to download and install 
              </Button>
              </div> */}


  <div >
      <h2 style={{ paddingTop: '20px' , paddingLeft: '20px' ,  color: '#fa370c' }}>  How to download and install Check the below video </h2>
      <ReactPlayer
      style={{ paddingTop: '20px'  }}
        url="https://youtu.be/yQKodC1zp8k"
        controls={true} // Show controls for the player
        width="100%"    // Set the width of the player
        height="500px"  // Set the height of the player
      />
    </div>








      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default AnyDesk;
