
import './App.css';
// import MainRouter from './Router/MainRouter';
import 'bootstrap/dist/css/bootstrap.min.css';


import React from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './MainPage';
import OperatingSystemMainPage from './pages/OperatingSystemMainPage';

import DevelopmentPage from './pages/DevelopmentPage';
import EducationPage from './pages/EducationPage';
import MostPopularPage from './pages/Most PopularPage';
import GamesPage from './pages/GamesPage';
import MultiMediaPage from './pages/MultiMediaPage';
import ListPage from './pages/ListPage';
import Windows11New from './Components/OperatingSystem/windows11New';
import Windows10sp1 from './Components/OperatingSystem/windows10sp1';
import Windows8 from './Components/OperatingSystem/windows8';
import Windows7SP2 from './Components/OperatingSystem/Windows7SP2';
import ManjaroLinux from './Components/OperatingSystem/ManjaroLinux';
import OSZorin17 from './Components/OperatingSystem/OSZorin17';
import Mojave from './Components/OperatingSystem/Mojave';
import AntiAvest from './MainBody/AntiAvest';
import GoogleChrome1 from './MainBody/GoogleChrome1';
import AvgAnti from './MainBody/AvgAnti';
import VLCMedia from './MainBody/VLCMedia';
import WinRAR from './MainBody/WinRAR';
import CCleaner from './MenuBar/CCleaner';
import WhatsAppMessenger1 from './MainBody/WhatsAppMessenger1';
import AnyDesk from './MainBody/AnyDesk';
import Rufus from './MainBody/Rufus';
import ThreeuTool from './MainBody/ThreeuTools';
import OSXLion from './Components/OperatingSystem/OSXLion';
import AdobeIllustrator1 from './Components/GraphicDesign/AdobeIllustrator1';
import AdobePhotoshop24 from './Components/GraphicDesign/AdobePhotoshop24';
import AdobePhotoshopCC2017 from './Components/GraphicDesign/AdobePhotoshopCC2017';
import CorelDRAWGraphics24 from './Components/GraphicDesign/CorelDRAWGraphics24';
import CorelDRAWGraphics2022 from './Components/GraphicDesign/CorelDRAWGraphics2022';
import AdobeAferEffect2018 from './Components/GraphicDesign/AdobeAferEffect2018';
import MossaikClassicPro from './Components/GraphicDesign/MossaikClassicPro';
import LogoCreaction24 from './Components/GraphicDesign/LogoCreaction24';
import TopazStudio24 from './Components/GraphicDesign/TopazStudio24';
import PopcardDesigner24 from './Components/GraphicDesign/PopcardDesigner24';
import AndroidStudio2024 from './Components/Development/AndroidStudio2024';
import PHPRunner24 from './Components/Development/PHPRunner24';
import RanorexStudio from './Components/Development/RanorexStudio';
import FileMakerserver24 from './Components/Development/FileMakerserver24';
import JetBrainsRider from './Components/Development/JetBrainsRider';
import JetBrainsWebStorm from './Components/Development/JetBrainsWebStorm';
import JetBrainsDataGrip from './Components/Development/JetBrainsDataGrip';
import JetBrainsRubyMine from './Components/Development/JetBrainsRubyMine';
import JetBrainsPyCharm from './Components/Development/JetBrainsPyCharm';
import JetBrainsGoLand from './Components/Development/JetBrainsGoLand';
import DragonriseGames from './Components/Games/DragonriseGames';
import AepGames from './Components/Games/AepGames';
import GooglePlayGames from './Components/Games/GooglePlayGames';
import SonicGames from './Components/Games/SonicGames';
import KidsLearningWord from './Components/Games/KidsLearningWord';
import StreetRacingGames from './Components/Games/StreetRacingGames';
import BlockPuzzleGames from './Components/Games/BlockPuzzleGames';
import DrawColorForKids from './Components/Games/Draw&ColorForKids';
import SniperGunShooter from './Components/Games/SniperGunShooter';
import FlashGames from './Components/Games/FlashGames';
import ItemPage from './Components/ListCat/ItemPage';
import AboutUs from './HeaderAndFooter/AboutUs';
import Mission from './HeaderAndFooter/OurMisssion';
import ContactUs from './HeaderAndFooter/ContactUs';
import GraphicDesignPage from './pages/GraphicDesignPage';
import TheFoundryNukeStudio2024 from './Components/MultiMedia/TheFoundryNukeStudio2024';
import GOMPlayerPlus2024 from './Components/MultiMedia/GOMPlayerPlus2024';
import NCHSpin3DPlus2024 from './Components/MultiMedia/NCHSpin3DPlus2024';
import ProPresenter2024 from './Components/MultiMedia/ProPresenter2024';
import LeawoProfMedia2024 from './Components/MultiMedia/LeawoProfMedia2024';
import AdobeBridge2024 from './Components/MultiMedia/AdobeBridge2024';
import CyberLinkColorDirector2024 from './Components/MultiMedia/CyberLinkColorDirector2024';
import AppleiTunes2024 from './Components/MultiMedia/AppleiTunes2024';
import EvaerVideoRecorderforSkype from './Components/MultiMedia/EvaerVideoRecorderforSkype';
import DVDFabToolkit2024 from './Components/MultiMedia/DVDFabToolkit2024';
import Windows7SP1 from './Components/OperatingSystem/Windows7SP1';
import CentOSStream from './Components/OperatingSystem/CentOSStream';
import IDM from './MainBody/IDM';
import PhotoTransferGuide from './Components/Education/PhotoTransferGuide';

import Page2HomeCateg from './pages/Page2HomeCateg';








function App() {
  return (
    <Router>
    <div>
      <Routes>
        {/* Define routes for components */}
        <Route path="/" element={<MainPage />} />
        <Route path="/operatingSystem" element={<OperatingSystemMainPage />} />
        <Route path="/graphicDesignpage" element={<GraphicDesignPage/>} />
        <Route path="/development" element={<DevelopmentPage />} />
        <Route path="/education" element={<EducationPage />} />
        <Route path="/Mostpopular" element={<MostPopularPage />} />
        <Route path="/games" element={<GamesPage />} />
        <Route path="/multimedia" element={<MultiMediaPage/>} />
        <Route path="/categories" element={<ListPage/>} />
       
    
    {/* Operating System Page Routing */}
        <Route path="/windows11new" element={<Windows11New />} />
        <Route path="/windows10sp1" element={<Windows10sp1 />} /> 
        <Route path="/windows8" element={<Windows8/>} /> 
        <Route path="/windows7Sp2" element={<Windows7SP2 />} /> 
        <Route path="/windowsSP1" element={<Windows7SP1 />} /> 
        <Route path="/ManjaroLinex" element={<ManjaroLinux />} /> 
        <Route path="/ZorinOS17" element={<OSZorin17/>} /> 
        
        <Route path="/Mojave" element={<Mojave/>} /> 
        <Route path="/CentOSStream" element={<CentOSStream/>} /> 
        <Route path="/IDM" element={<IDM/>} /> 
        
       

       
    
    {/* Main Home Page Routing */}
    <Route path="/AvastAntivirusoft" element={<AntiAvest/>} /> 
    <Route path="/GoogleChrome1" element={<GoogleChrome1/>} />
    <Route path="/AVGAntiVirus" element={<AvgAnti/>} />
    <Route path="/VLCMedia" element={<VLCMedia/>} />
    <Route path="/WinRAR64bit" element={<WinRAR/>} />
    <Route path="/CCleaner" element={<CCleaner/>} />
    <Route path="/WhatsAppMessenger1" element={<WhatsAppMessenger1/>} />
    <Route path="/AnyDesk" element={<AnyDesk/>} />
    <Route path="/Rufus" element={<Rufus/>} />
    <Route path="/ThreeuTools" element={<ThreeuTool/>} />
    <Route path="/OSXLion" element={<OSXLion/>} />
    
    {/* Main Graphic Design Page Routing */}
    
    <Route path="/AdobeIllustrator1" element={<AdobeIllustrator1/>} />
    <Route path="/AdobePhotoshop24" element={<AdobePhotoshop24/>} />
    <Route path="/AdobePhotoshopCC2017" element={<AdobePhotoshopCC2017/>} />
    <Route path="/CorelDRAWGraphics24" element={<CorelDRAWGraphics24/>} />
    <Route path="/CorelDRAWGraphics2022" element={<CorelDRAWGraphics2022/>} />
    
    <Route path="/AdobeAfterEffect" element={<AdobeAferEffect2018/>} />
    <Route path="/MossaikClassicPro" element={<MossaikClassicPro/>} />
    <Route path="/OlympiaLogoCreation2024" element={<LogoCreaction24/>} />
    <Route path="/TopazStudio" element={<TopazStudio24/>} />
    <Route path="/PopcardDesigner24" element={<PopcardDesigner24/>} />
    
    {/* Main Development Page Routing */}
    
    <Route path="/AndroidStudio2024" element={<AndroidStudio2024/>} />
    <Route path="/PHPRunner24" element={<PHPRunner24/>} />
    <Route path="/RanorexStudio4" element={<RanorexStudio/>} />
    <Route path="/FileMakerServer" element={<FileMakerserver24/>} />
    <Route path="/JetBrainsRider" element={<JetBrainsRider/>} />
    <Route path="/JetBrainsWebStorm" element={<JetBrainsWebStorm/>} />
    <Route path="/JetBrainsDataGrip" element={<JetBrainsDataGrip/>} />
    <Route path="/JetBrainsRubyMine" element={<JetBrainsRubyMine/>} />
    <Route path="/JetBrainsPyCharm" element={<JetBrainsPyCharm/>} />
    <Route path="/JetBrainsGoLand" element={<JetBrainsGoLand/>} />
    
    
    {/* Main Games Page Routing */}
    
    <Route path="/DragonriseGamesYoloMouse" element={<DragonriseGames/>} />
    <Route path="/GamesTeaserAEP" element={<AepGames/>} />
    <Route path="/GooglePlayGames" element={<GooglePlayGames/>} />
    <Route path="/SonicGames" element={<SonicGames/>} />
    <Route path="/KidsLearningWord" element={<KidsLearningWord/>} />
    <Route path="/StreetRacingGames" element={<StreetRacingGames/>} />
    <Route path="/BlockPuzzleGames" element={<BlockPuzzleGames/>} />
    <Route path="/DrawColorForKids" element={<DrawColorForKids/>} />
    <Route path="/SniperGunShooter" element={<SniperGunShooter/>} />
    <Route path="/FlashGames" element={<FlashGames/>} />
    
    <Route path="/TheFoundryNukeStudio2024" element={<TheFoundryNukeStudio2024/>} />
    <Route path="/GOMPlayerPlus2024" element={<GOMPlayerPlus2024/>} />
    <Route path="/NCHSpin3DPlus2024" element={<NCHSpin3DPlus2024/>} />
    <Route path="/ProPresenter2024" element={<ProPresenter2024/>} />
    <Route path="/LeawoProfMedia2024" element={<LeawoProfMedia2024/>} />
    <Route path="/AdobeBridge2024" element={<AdobeBridge2024/>} />
    <Route path="/CyberLinkColorDirector2024" element={<CyberLinkColorDirector2024/>} />
    <Route path="/AppleiTunes2024" element={<AppleiTunes2024/>} />
    <Route path="/EvaerVideoRecorderforSkype" element={<EvaerVideoRecorderforSkype/>} />
    <Route path="/DVDFabToolkit2024" element={<DVDFabToolkit2024/>} />

    
    
        <Route path="/item/:id" element={<ItemPage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/services" element={<Mission />} />
        <Route path="/contact" element={<ContactUs />} />
        
        <Route path="/PhotoTransferGuide" element={<PhotoTransferGuide />} />


      {/* page 2 load  */}
        <Route path="/PageCategories" element={<Page2HomeCateg />} />
        






    
      </Routes>
    </div>
    </Router>
  );
}

export default App;
