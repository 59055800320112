import React from 'react';
import TopNavbar from '../../HeaderAndFooter/TopHeader';
import LogoComponent from '../../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../../MenuBar/SocialMediaSidebar';
import MenuBar from '../../MenuBar/MenuBar';
import Footer from '../../HeaderAndFooter/Footer';
const PhotoTransferGuide = () => {
  return (

<div class="background-image">
   <TopNavbar />
   <LogoComponent />
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>

    <div className="text-area">
    <div className='text-mainArea'> 
    


      <h1 style={{ paddingLeft: '30px' , color: '#0fbf5f', marginTop: '20px'  }}>How to Transfer Photos from iPhone to Mac</h1>

      <section>
        <h2 style={{ paddingLeft: '20px' , marginTop: '30px' , color: '#f49c0b' }}>Method 1: Using a USB Cable and Photos App</h2>
        <ol>
          <li> <strong style={{  color: '#0c6dc9' }}>Connect Your iPhone to Your Mac:</strong>
            <ul>
              <li > Use a Lightning or USB-C cable to connect your iPhone to your Mac.</li>
              <li>Unlock your iPhone and tap <strong>Trust</strong> if prompted to trust the computer.</li>
            </ul>
          </li>
          <li> <strong style={{  color: '#0c6dc9' }}>Open the Photos App: </strong>
            <ul>
              <li>On your Mac, open the <strong>Photos</strong> app (it should open automatically when your iPhone is connected).</li>
            </ul>
          </li>
          <li> <strong style={{  color: '#0c6dc9' }}>Import Photos:</strong>
            <ul>
              <li>In the Photos app, select your iPhone under the <strong>Devices</strong> section.</li>
              <li>Choose the photos or videos you want to transfer, or click <strong>Import All New Items</strong>.</li>
              <li>Select a destination folder or album to save the imported photos.</li>
            </ul>
          </li>
          <li> <strong style={{  color: '#0c6dc9' }}>Eject Your iPhone: </strong>
            <ul>
              <li>Once the transfer is complete, eject your iPhone safely by clicking the eject icon next to its name in the Photos app.</li>
            </ul>
          </li>
        </ol>
      </section>


      <img 
        src='/images/MultiMedia/iphoneimage1.jpg' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />






      <section>
        <h2 style={{ paddingLeft: '30px' , color: '#f49c0b', marginTop: '20px'  }}>Method 2: Using AirDrop</h2>
        <ol>
          <li> <strong style={{  color: '#0c6dc9' }}>Enable AirDrop on Both Devices:</strong>
            <ul>
              <li>On your iPhone, swipe down to open the Control Center, press and hold the network settings card, and tap <strong>AirDrop</strong>. Set it to <strong>Everyone</strong> or <strong>Contacts Only</strong>.</li>
              <li>On your Mac, open <strong>Finder</strong>, click <strong>AirDrop</strong> in the sidebar, and set it to <strong>Everyone</strong> or <strong>Contacts Only</strong>.</li>
            </ul>
          </li>
          <li> <strong style={{  color: '#0c6dc9' }}>Select Photos on Your iPhone:</strong>
            <ul>
              <li>Open the <strong>Photos</strong> app on your iPhone, select the photos you want to transfer, and tap the <strong>Share</strong> icon.</li>
            </ul>
          </li>
          <li> <strong style={{  color: '#0c6dc9' }}>Send via AirDrop:</strong>
            <ul>
              <li>Tap <strong>AirDrop</strong> and select your Mac from the list of available devices.</li>
              <li>On your Mac, accept the incoming files, and they will be saved to the <strong>Downloads</strong> folder by default.</li>
            </ul>
          </li>
        </ol>
      </section>

      <img 
        src='/images/MultiMedia/iphonairdrop.jpg' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />




      <section>
        <h2 style={{ paddingLeft: '30px' , color: '#f49c0b', marginTop: '20px'  }}>Method 3: Using iCloud Photos</h2>
        <ol>
          <li> <strong style={{  color: '#0c6dc9' }}>Enable iCloud Photos:</strong>
            <ul>
              <li>On your iPhone, go to <strong>Settings</strong> &gt; <strong>[Your Name]</strong> &gt; <strong>iCloud</strong> &gt; <strong>Photos</strong>, and turn on <strong>iCloud Photos</strong>.</li>
              <li>On your Mac, go to <strong>System Preferences</strong> &gt; <strong>Apple ID</strong> &gt; <strong>iCloud</strong>, and enable <strong>Photos</strong>.</li>
            </ul>
          </li>
          <li> <strong style={{  color: '#0c6dc9' }}>Sync Photos:</strong>
            <ul>
              <li>Once iCloud Photos is enabled, your photos will automatically sync across all devices connected to the same Apple ID.</li>
              <li>Open the <strong>Photos</strong> app on your Mac to access your iPhone photos.</li>
            </ul>
          </li>
        </ol>
      </section>

      <img 
        src='/images/MultiMedia/icloudiphon.jpg' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />


      <section>
        <h2 style={{ paddingLeft: '30px' , color: '#f49c0b', marginTop: '20px'  }}>Method 4: Using Image Capture</h2>
        <ol>
          <li> <strong style={{  color: '#0c6dc9' }}>Connect Your iPhone to Your Mac:</strong>
            <ul>
              <li>Use a USB cable to connect your iPhone to your Mac.</li>
            </ul>
          </li>
          <li> <strong style={{  color: '#0c6dc9' }}>Open Image Capture:</strong>
            <ul>
              <li>On your Mac, open the <strong>Image Capture</strong> app (found in the Applications folder or via Spotlight Search).</li>
            </ul>
          </li>
          <li> <strong style={{  color: '#0c6dc9' }}>Select Photos:</strong>
            <ul>
              <li>In Image Capture, select your iPhone from the list of devices.</li>
              <li>Choose the photos you want to transfer and select a destination folder.</li>
            </ul>
          </li>
          <li> <strong style={{  color: '#0c6dc9' }}>Import Photos:</strong>
            <ul>
              <li>Click <strong>Import</strong> or <strong>Import All</strong> to transfer the selected photos to your Mac.</li>
            </ul>
          </li>
        </ol>
      </section>

      <img 
        src='/images/MultiMedia/ImageCaptureApp.jpg' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />



      <section>
        <h2 style={{ paddingLeft: '30px' , color: '#f49c0b', marginTop: '20px'  }}>Method 5: Using Third-Party Apps</h2>
        <p style={{ paddingLeft: '30px'  }}>
          You can also use third-party apps like <strong>Google Photos</strong>, <strong>Dropbox</strong>, or <strong>AnyTrans</strong> to transfer photos wirelessly or via a USB connection.
        </p>
      </section>


      <img 
        src='/images/MultiMedia/thirdpartyApp.jpg' 
        alt="Windows 11 Logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' ,
        }} 
      />
    
      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
};

export default PhotoTransferGuide;