import React from 'react';
import TopNavbar from './HeaderAndFooter/TopHeader';
import MenuBar from './MenuBar/MenuBar';
import SocialMediaSidebar from './MenuBar/SocialMediaSidebar';
import LogoComponent from './HeaderAndFooter/PageLogo';
import CategoryList from './MainBody/categories';
import Footer from './HeaderAndFooter/Footer';
import './css/mainpage.css';
import './MenuBar/WhatsAppButton';
import WhatsAppButton from './MenuBar/WhatsAppButton';

const MainPage = () => {
  return (
    <div className="background-image">
    <TopNavbar />
    <LogoComponent/>
    <SocialMediaSidebar />
    <WhatsAppButton/>
    <div  className="main-layout" style={{ marginTop: '10px' }}>
      <MenuBar />
    </div>

    <div  className="main-layout" style={{ marginTop: '80px' }}>
      <CategoryList />
      
    </div>
    <Footer />
  </div>
  );
}

export default MainPage;
