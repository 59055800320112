import React, { useState } from "react";
import '../OperatingSystem/css/operatingsysyemCat.css';
import { useNavigate } from "react-router-dom";


const categories = [


  {
    id:1,
    image: 'images/AITools/chatgptlogo.jpg',
    title: 'ChatGPT ',
    time: ' chatgpt.If you went to use the chatgpt tool, click here.',
    details: 'Powered by learning, ChatGPT is an advanced AI language model developed by OpenAI to generate human-like text, answer questions, write content, and more. It understands context and delivers consistent, appropriate responses for both general and professional use.',
  },
  {
    id: 2,
    image: 'images/AITools/MetaAILogo.jpg',
    title: 'Meta AI',
    time: '.If you went to use the Meta AI, click here.',
    details: 'Meta AI, a division of Meta (formerly Facebook), focuses on developing advanced AI technologies to enhance human-computer interaction. Its goal is to drive innovation in areas like natural language processing, computer vision, machine learning, and ethical AI solutions.'
  },
  {
    id: 3,
    image: 'images/AITools/Perplexityalilogo.jpg',
    title: 'Perplexity',
    time: 'If you went to use the Perplexity AI , click here.',
    details: 'Perplexity is a metric used to evaluate language models in NLP, measuring how well a model predicts text, with lower perplexity indicating better accuracy. It reflects the models uncertainty or surprise when predicting the next word, often used in tasks like language modeling, message creation, and machine translation.'
  }
  ,
  {
    id: 4,
    image: 'images/AITools/copilot-logo.jpg',
    title: 'Copilot ',
    time: 'If you went to use the Microsoft Copilot AI , click here.',
    details: 'Copilot is an AI-powered code completion tool developed by GitHub and OpenAI, helping developers write code by suggesting snippets, functions, and solutions based on context. Integrated with Visual Studio Code, it boosts productivity by accelerating work, reducing errors, and offering real-time support for various programming languages and frameworks.'
  }
  

  ,
  {
    id: 5,
    image: 'images/AITools/GemaniAi-logo.jpg',
    title: 'Gemini AI ',
    time: 'If you went to use the Gemini AI , click here.',
    details: 'Gemini is an advanced AI model suite by Google DeepMind, designed to expand AI research and applications. By improving natural language processing and understanding, it enhances areas like search, creative work, and problem-solving, offering accurate, context-aware AI capabilities.'
  },



  {
    id: 6,
    image: 'images/AITools/Midjourney -logo.jpg',
    title: 'Midjourney ',
    time: 'If you went to use the Gemini AI , click here.',
    details: 'MidJourney is an advanced AI tool that creates stunning images and artwork from text prompts. Using cutting-edge machine learning, it allows users to generate detailed, unique art, offering creative freedom for artists, designers, and creators.'
  },
  {
    id: 7,
    image: 'images/AITools/Adobe Firefly-logo.jpg',
    title: 'Adobe Firefly ',
    time: 'If you went to use the Gemini AI , click here.',
    details: 'Adobe Firefly is a suite of generative AI tools designed for creative professionals to efficiently create images, graphics, and designs. Integrating with Adobe products like Photoshop and Illustrator, it generates high-quality visual content from text prompts while emphasizing ethical AI use and copyright-compliant datasets.'
  },
  {
    id: 8,
    image: 'images/AITools/Grammarly-logo.jpg',
    title: 'Grammarly',
    time: 'If you went to use the Gemini AI , click here.',
    details: 'Grammarly is an AI writing assistant that helps users correct grammar, spelling, punctuation, and tone in various formats, including emails, essays, and social media posts. It also enhances clarity, conciseness, and readability, with features like plagiarism checking and vocabulary enhancement, available as a browser extension, desktop app, or integrated into tools like Microsoft Word.'
  }
  ,
  {
    id: 9,
    image: 'images/AITools/ChatSonic-logo.jpg',
    title: 'ChatSonic ',
    time: 'If you went to use the Gemini AI , click here.',
    details: 'ChatSonic, developed by Writesonic, is an AI-powered tool that helps with content generation and communication, using advanced NLP for human-like responses in writing, brainstorming, and marketing, with features like voice-to-text, personalized replies, and real-time data updates for improved customer support and content creation.'
  }
  

  ,
  {
    id: 10,
    image: 'images/AITools/Jasper-logo.jpg',
    title: 'Jasper AI, Inc. ',
    time: 'If you went to use the Gemini AI , click here.',
    details: 'Jasper AI, formerly Jarvis, is a content generation platform that uses advanced NLP to quickly create high-quality, SEO-optimized content like social media posts, blogs, and ads, with customizable templates and workflows to boost productivity and creativity for marketers, entrepreneurs, and agencies.'
  }
];



// Component to display individual category information
const CategoryCard = ({ category, onClick }) => {


  return (
    <div className="category-card" onClick={onClick}>
      <img src={category.image} alt={category.title} className="category-image" />
      <div className="category-info">
        <h3>{category.title}</h3>
        <p className="category-time">Return to  {category.time}</p>
        <p>{category.details}</p>
      </div>
    </div>
  );
};





// Main Component to display the list of categories
const ResponsiveList = () => {
  const navigate = useNavigate();  // React Router's navigation hook

  const handleCardClick = (categoryId) => {
    if (categoryId === 1) {
      window.location.href = "https://chatgpt.com/";
      // Navigate to the Windows 7 page
    } else if (categoryId === 2) {

      window.location.href = "https://www.meta.ai/";
    }
    else if (categoryId === 3) {
      window.location.href = "https://www.perplexity.ai/";
    }
    else if (categoryId === 4) {
      window.location.href = "https://copilot.microsoft.com/chats/yt3hDjqseb12fjKndZiyt";
    }
    else if (categoryId === 5) {
      window.location.href = "https://gemini.google.com/";
    }
    else if (categoryId === 6) {
      window.location.href = "https://www.imagine.art/?utm_source=google&utm_medium=cpc&utm_campaign=G_I_Web_T2_WW_New&utm_term=midjourney%20ai&utm_campaign=&utm_source=adwords&utm_medium=ppc&hsa_acc=3029240990&hsa_cam=22095383460&hsa_grp=174807442964&hsa_ad=728312931535&hsa_src=g&hsa_tgt=kwd-1675638903539&hsa_kw=midjourney%20ai&hsa_mt=b&hsa_net=adwords&hsa_ver=3&gad_source=1&gclid=EAIaIQobChMItoCAgJv8igMVyJpoCR06_jWaEAAYASAAEgKDP_D_BwE";
    }
    else if (categoryId === 7) {
      window.location.href = "https://www.meta.ai/";
    }
    
    else if (categoryId === 8) {
      window.location.href = "https://www.meta.ai/";
    }
    
    
    else if (categoryId === 9) {
      window.location.href = "https://www.meta.ai/";
    }
    
    else if (categoryId === 10) {
      window.location.href = "https://www.meta.ai/";
    }
    
    
  };



  return (
    <div className="Main-category">
    <div className="category-list">
      {categories.map((category) => (
        <CategoryCard key={category.id} category={category} onClick={() => handleCardClick(category.id)} />
      ))}


    </div>
   
    </div>
  );
};

export default ResponsiveList;



