import React from 'react';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TopNavbar from '../HeaderAndFooter/TopHeader';
import LogoComponent from '../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../MenuBar/SocialMediaSidebar';
import MenuBar from '../MenuBar/MenuBar';
import Footer from '../HeaderAndFooter/Footer';
import ReactPlayer from "react-player";



const ThreeuTool= () => {



    const handleDownload = () => {
      // Your download logic here
      // window.location.href = 'https://www.3u.com/';
      window.location.href = 'SoftwareHere/homesoft/3utools-3.20.017-installer_B-53lw1.exe';
     
    };
   


  return (
    <div class="background-image">
   <TopNavbar/>
   <LogoComponent />
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2 style={{ textAlign: 'center', color: '#4f4bff', marginTop: '10px' }}>Overview of 3uTools for Windows</h2>
      <p style={{ paddingLeft: '5px' }}>
      3uTools is a comprehensive management tool designed for iOS devices.
        Offers a wide range of features to help users manage and
        Optimize their equipment It has functions like file
        Management Backup System Optimization and flashing firmware
        This makes it an essential tool for iPhone and iPad users.
      </p>

      <h2 style={{ paddingLeft: '20px' , color: '#4f4bff' }}>Key Features</h2>
      <ul>
        <li>
          <strong>Device Management:</strong> Easily manage files, photos,
          music, and videos on your iOS device with an intuitive interface.
        </li>
        <li>
          <strong>Data Backup and Restore:</strong> Quickly back up your data,
          including contacts, messages, and app data, with the option to
          restore it later.
        </li>
        <li>
          <strong>Firmware Flashing:</strong> Supports downloading and flashing
          iOS firmware, allowing users to upgrade or downgrade their device
          software.
        </li>
        <li>
          <strong>App Management:</strong> Install, uninstall, and manage apps
          on your device without needing iTunes.
        </li>
        <li>
          <strong>Ringtone Maker:</strong> Create custom ringtones from your
          music library and transfer them to your iPhone.
        </li>
        <li>
          <strong>Battery Health Monitoring:</strong> Provides detailed
          information about your device's battery health and status.
        </li>
        <li>
          <strong>System Optimization:</strong> Offers tools to clean junk
          files, optimize system performance, and free up storage space.
        </li>
        <li>
          <strong>Real-Time Device Information:</strong> Displays comprehensive
          information about your device, including model, iOS version, and
          available storage.
        </li>
        <li>
          <strong>iCloud Management:</strong> Manage your iCloud account and
          files directly from the application.
        </li>
        <li>
          <strong>Multi-Language Support:</strong> Available in multiple
          languages to cater to a global user base.
        </li>
      </ul>

      <h2 style={{ paddingLeft: '20px' , color: '#4f4bff' }}>System Requirements</h2>
      <ul>
        <li>
          <strong>Operating System:</strong> Windows 7, Windows 8, Windows 10,
          or later.
        </li>
        <li>
          <strong>Processor:</strong> Intel Pentium 4 or later.
        </li>
        <li>
          <strong>RAM:</strong> Minimum of 1 GB (2 GB recommended).
        </li>
        <li>
          <strong>Free Disk Space:</strong> At least 100 MB for installation.
        </li>
        <li>
          <strong>USB Port:</strong> Required for connecting your iOS device.
        </li>
      </ul>

      <img 
      src='/images/MostP/3utopolsforwindows.png' 
        alt="window11 logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' 
        }}
        
      />



      <h2 style={{ paddingTop: '20px' ,paddingLeft: '20px' , color: '#4f4bff' }}>Conclusion</h2>
      <p style={{ paddingLeft: '5px' }}>
      3uTools is a powerful and versatile tool for managing iOS devices.
        It offers such features for both casual and advanced users.
        With an easy-to-use interface and comprehensive capabilities.
        Reduce the hassle of file management Backing up data, firmware, and other tasks
        Updates, adding value to iOS users' toolkits
      </p>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '10px',
      }}
    >
      3uTools for Windows
    </Button>

</div>

<p style={{ paddingLeft: '5px' }}>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
           


   <div >
      <h2 style={{ paddingTop: '20px' , paddingLeft: '20px' ,  color: '#4f4bff' }}>  How to download and install Check the below video </h2>
      <ReactPlayer
      style={{ paddingTop: '20px'  }}
        url="https://youtu.be/7TY6-kah-xk"
        controls={true} // Show controls for the player
        width="100%"    // Set the width of the player
        height="500px"  // Set the height of the player
      />
    </div>



      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default ThreeuTool ;
