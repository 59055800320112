import React from 'react';
import '../css/categories.css';
import './NextButton.css';
import { useNavigate } from "react-router-dom";
// Sample data (You can replace this with dynamic data later)
const categories = [
  {
    id:1,
    image: 'images/MostP/idm.jpg',
    title: ' Internet Download Manager (IDM) ',
    time: 'Free Download',
     details: 'Boosts download speeds five times faster.'
  },
 

  {
    id:2,
    image: 'images/MostP/Avestt.png',
    title: ' Avast Antivirus ',
    time: 'Free Download',
     details: 'Real-time protection against malware, viruses, ransomware, and phishing attacks'
  },
  {
    id: 3,
    image: 'images/MostP/Chrome.png',
    title: 'Google Chrome  ',
    time: 'Free Download ',
    details: 'Fast, secure browser with intuitive interface.'
  },
  {
    id: 4,
    image: 'images/MostP/AVGg.png',
    title: 'AVG AntiVirus ',
    time: 'Free Download ',
    details: 'Robust security solution providing real-time virus, malware, spyware, and phishing attacks protection.'
  }
  ,
  {
    id: 5,
    image: 'images/MostP/VLCC.png',
    title: 'VLC Media Player ',
    time: 'Free Download',
    details: 'Media player that reads most audio and video formats as well as DVDs, Blu-rays, and streaming protocols.'
  }
  

  ,
  {
    id: 6,
    image: 'images/MostP/Winrar.png',
    title: ' WinRAR 64-bit',
    time: 'Free Download',
    details: 'File compression and archiving tool, with support for formats such as RAR and ZIP.'
  },



  {
    id: 7,
    image: 'images/MostP/cclear.png',
    title: ' CCleaner ',
    time: 'Free Download',
    details: 'Clean temporary files, manage startup items and fix registry issues.'
  },
  {
    id: 8,
    image: 'images/MostP/whatsapp.png',
    title: ' WhatsApp Messenger 64-bit ',
    time: 'Free Download',
    details: 'Texting and making voice and video calls, sharing files.'
  },
  {
    id: 9,
    image: 'images/MostP/anyDesk.png',
    title: ' AnyDesk ',
    time: 'Free Download',
    details: 'AnyDesk is one of the smallest remote desktop applications for Windows. '
  }
  ,
  {
    id: 10,
    image: 'images/MostP/Rufus.png',
    title: 'Rufus for Windows ',
    time: 'Free Download ',
    details: 'Creating bootable USB drives, making it ideal for installing operating systems or running live systems.'
  }
  

  ,
  {
    id: 11,
    image: 'images/MostP/3utools.png',
    title: ' 3uTools ',
    time: 'Free Download',
    details: 'Manage iOS devices with advanced features.'
  }
];








// Component to display individual category information
const CategoryCard = ({ category, onClick }) => {
  return (
    <div className="category-card"  onClick={onClick}>
      <img src={category.image} alt={category.title} className="category-image" />
      <div className="category-info">
        <h3>{category.title}</h3>
        <p style={{  color: '#0ec935' }} > {category.time}</p>
        <p>{category.details}</p>
      </div>
    </div>
  );
};









// Main Component to display the list of categories
const CategoryList = () => {

  const navigate = useNavigate();  // React Router's navigation hook

  const handleCardClick = (categoryId) => {
    if (categoryId === 1) {
      navigate("/IDM");
    } else if (categoryId === 2) {
        navigate("/AvastAntivirusoft"); // Navigate to the Windows 7 page
    } else if (categoryId === 3) {
      navigate("/GoogleChrome1"); // Navigate to the Windows 10 page
    }else if (categoryId === 4) {
      navigate("/AVGAntiVirus"); // Navigate to the Windows 10 page
    }else if (categoryId === 5) {
      navigate("/VLCMedia"); 
    }
    else if (categoryId === 6) {
      navigate("/WinRAR64bit"); // Navigate to the Windows 10 page
    }
    else if (categoryId === 7) {
      navigate("/CCleaner"); // Navigate to the Windows 10 page
    }
    else if (categoryId === 8) {
      navigate("/WhatsAppMessenger1"); // Navigate to the Windows 10 page
    }
    else if (categoryId === 9) {
      navigate("/AnyDesk"); // Navigate to the Windows 10 page
    }
    else if (categoryId === 10) {
      navigate("/Rufus"); // Navigate to the Windows 10 page
    }
    else if (categoryId === 11) {
      navigate("/ThreeuTools"); // Navigate to the Windows 10 page
    }

  };

  const Loadpage2 = () => {

    navigate("/PageCategories"); 

  }





  return (
    <div className="Main-category">
    <div className="category-list">
      {categories.map((category) => (
        <CategoryCard  key={category.id} category={category} onClick={() => handleCardClick(category.id)} />
      ))}
    </div>
  

    <button className="next-button"  onClick={Loadpage2}>
      2
     
    </button>
    <button className="next-button2">
      3
      
    </button>
  
    </div>
  );
};

export default CategoryList;
