import React from 'react';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import TopNavbar from '../HeaderAndFooter/TopHeader';
import LogoComponent from '../HeaderAndFooter/PageLogo';
import SocialMediaSidebar from '../MenuBar/SocialMediaSidebar';
import MenuBar from '../MenuBar/MenuBar';
import Footer from '../HeaderAndFooter/Footer';
import ReactPlayer from "react-player";


const IDM= () => {



    const handleDownload = () => {
      // Your download logic here
      // window.location.href = 'https://getintopc.com/softwares/download-managers/internet-download-manager-v6-42-build-26-free-download/';

      window.location.href = 'SoftwareHere/homesoft/Internet_Download_Manager_v6.42_Build_26.zip';
    };


 
    


  return (
    <div class="background-image">
   <TopNavbar/>
   <LogoComponent />
   <SocialMediaSidebar />


    <div  className="main-layout" >
    <MenuBar />
    </div>

    <div className="container" style={{ marginTop: '50px' }}>
      <div className="text-area">
    <div className='text-mainArea'> 

    <h2 style={{ textAlign: 'center', color: '#fa370c', marginTop: '10px' }}>Free Download Internet Download Manager (IDM)</h2>
      <p style={{ paddingLeft: '5px' }}>
      Internet Download Manager (IDM) is a widely used software application designed to enable efficient and faster downloads of files from the web. Download speeds can be up to 5 times faster, and it supports multiple connection to downloads. It also supports pause/resume, scheduling and supports integration with all popular web browsers. IDM is a powerful download manager that helps accelerate download speeds.
      </p>

      

      <h2 style={{ paddingLeft: '20px' , color: '#fa370c' }}>System Requirements</h2>
      <ul>
        <li><strong>Operating System:</strong> Windows 11, 10, 8, 7, Vista, XP (both 32-bit and 64-bit)
        </li>
        <li><strong>Processor:</strong> Minimum 1 GHz processor</li>
        <li><strong>RAM:</strong> 512 MB of RAM (1 GB recommended)</li>
        <li><strong>Free Disk Space:</strong> 50 MB of free hard disk space for installation</li>

      </ul>


      <img 
      src='/images/MostP/idmmain.jpg' 
        alt="window11 logo" 
        style={{
          width: '600px',
          height: '400px',
          display: 'block', // Ensures block-level behavior for centering
          marginLeft: 'auto', 
          marginRight: 'auto' 
        }}
        
      />




      <h2 style={{ paddingTop: '20px', paddingLeft: '20px' , color: '#fa370c' }}>Conclusion</h2>
      <p>
      Internet Download Manager is indeed a powerful and reliable tool that enhances the downloading process with fast download speeds, easy management, and wide-browser compatibility. Whether you're looking for downloading large files or managing multiple downloads for this purpose or otherwise, be it to schedule content in an orderly fashion, IDM provides a seamless solution for you with regard to efficiency and convenience. Its user-friendly interface, combined with advanced features, makes it a must-have for anyone who frequently downloads from the internet.
      </p>


      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>

      <Button
      variant="contained"
      color="primary"
      size="large"
      startIcon={<DownloadIcon />}
      onClick={handleDownload}
      style={{
        backgroundColor: '#0078D4',
        color: '#fff',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        textTransform: 'none',
        marginBottom: '10px',
      }}
    >
Internet Download Manager (IDM)
    </Button>
</div>



    <p>
              If the application is not installed on your Windows system, click the button below to proceed with the installation.
            </p>
            {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<DownloadIcon />}
                onClick={handleclickvideos}
                
                style={{
                  backgroundColor: 'green',
                  color: '#fff',
                  padding: '10px 20px',
                  marginLeft: '10px',
                  fontSize: '16px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  marginBottom: '20px',
                }}
              >
                How to download and install 
              </Button>
              </div> */}


  <div >
      <h2 style={{ paddingTop: '20px' , paddingLeft: '20px' ,  color: '#fa370c' }}>  How to download and install Check the below video </h2>
      <ReactPlayer
      style={{ paddingTop: '20px'  }}
        url=" "
        controls={true} // Show controls for the player
        width="100%"    // Set the width of the player
        height="500px"  // Set the height of the player
      />
    </div>








      </div>
      </div>
      <div className="empty-area"></div>
    </div>
  <Footer />
  </div>
  );
}

export default IDM;
