import React from 'react';
import './WhatsAppButton.css'; // Assuming you have a separate CSS file for styling

const WhatsAppButton = () => {
  const phoneNumber = '+923435314371'; // Replace this with your phone number (including country code)

  return (
    <a
      href={`https://wa.me/${phoneNumber}`}
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-button"
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" // WhatsApp icon
        alt="WhatsApp"
        className="whatsapp-icon"
      />
    </a>
  );
};

export default WhatsAppButton;
